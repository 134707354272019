import req from "./req"


export const send_sms = function (data){
    return req({
        url:'/api/seller/login/send_sms',
        data:data
    })
}

export const auth = function (data) {
	return req({
		url: '/seller/login/auth',
		  data:data
	})
}

export const login = function (data) {
	return req({
		url: '/api/seller/login/verify',
		 data:data
	})
}



export const get_bill_list = function (data) {
	return req({
		url: '/api/seller/bill/get_bill_list',
		data:data
	})
}



export const get_bill_info = function (data) {
	return req({
		url: '/api/seller/bill/get_bill_info',
		data:data
	})
}

export const seller_sign = function (data) {
	return req({
		url: '/api/seller/bill/sellerSign',
		data:data
	})
}

// export const upload_serve = function (data) {
// 	return req({
// 		url: '/api/seller/upload/upload_serve',
// 		data:data
// 	})
// }

let upload_api = (data) => new Promise((resolve, rej) => {
	req({
		url: '/api/seller/upload/upload',
	}).then(res => {
		let file = data.data.file
		let name = file.name.split('.')
		let key = res.data.dir + new Date().getTime() + '.' + name[name.length - 1]
		req({
			global_url: res.data.host,
			mode: 'upload',
			is_oss: true,
			loading: true,
			header: {
				"Access-Control-Allow-Credentials": "true"
			},
			data: {
				OSSAccessKeyId: res.data.accessid,
				policy: res.data.policy,
				Signature: res.data.signature,
				key: key,
				success_action_status: '200',
				file: file
			}
		}).then(file_res => {
			resolve({
				upload_data:{
					url:res.data.url + '/' + key+'?x-oss-process=style/visit'
				}
			})
		})
	})
})
export const upload_oss = upload_api;

export const rotateImage = function (data) {
	return req({
		url: '/api/seller/upload/rotateImage',
		data:data
	})
}


// //同住方案节省支出
// export const liveTogetherPrice = function (data) {
// 	return req({
// 		url: '/manage/crm.trip_bi/liveTogetherPrice',
// 		data:data
// 	})
// }

// //top10部门审批时长
// export const topTenDepartApproval = function (data) {
// 	return req({
// 		url: '/manage/crm.trip_bi/topTenDepartApproval',
// 		data:data
// 	})
// }

// //地图顶部统计
// export const mapTopCount = function (data) {
// 	return req({
// 		url: '/manage/crm.trip_bi/mapTopCount',
// 	data:data
// 	})
// }

// //orderHotelMap地图
// export const orderHotelMap = function (data) {
// 	return req({
// 		url: '/manage/crm.trip_bi/orderHotelMap',
// 		...data
// 	})
// }

// // //订单分布地图

// export const topDepartMissing = function (data) {
// 	return req({
// 		url: '/manage/crm.trip_bi/topDepartMissing',
// 		...data
// 	})
// }