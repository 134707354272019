import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import moment from 'moment'
import './assets/css/app.less'
import router from './router/index.js'
Vue.prototype.$moment = moment;
Vue.config.productionTip = false
Vue.use(Vant);
new Vue({
	router,
  render: function (h) { return h(App) },
}).$mount('#app')
