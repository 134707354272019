import Vue from 'vue'
import axios from 'axios'
import {
	Toast,Dialog
} from 'vant';
import 
	config
 from './methods.js';

function error_handle(msg = '网络错误') {
	Toast(msg);
}
import router from '../../router/index.js'
//测试地址
// let config_host = 'http://crm-dev.waakuu.com'
// 正式地址
// let config_host ='https://yr.jxqnkj.com'
function req({
	url = '',
	mode = 'api',
	global_url = '',
	base_url = '',
	data = {},
	type = 'post',
	is_oss = false,
	loading = false,
	filePath = '',
}) {
	return new Promise((resolve, reject) => {
		url = config.hostUrl + url
		url = global_url ? global_url : url;
		let key = localStorage.getItem('token')
		if (!key) {
			let url = window.location.href;
			window.location.href =config.hostUrl+ '/seller/login/auth?url=' + url
		}
		let headers = {
			"content-type": "application/json",
			"token": key
		};
		let req_data;
		if (mode == 'upload') {
			headers['content-type'] = 'multipart/form-data';
			let formData = new FormData();
			formData.append('key', data['key']); //存储在oss的文件路径
			formData.append('OSSAccessKeyId', data['OSSAccessKeyId']); //accessKeyId
			formData.append('policy', data['policy']); //policy
			formData.append('Signature', data['Signature']); //签名
			formData.append("file", data['file']);
			formData.append('success_action_status', '200'); //成功后返回的操作码
			req_data = formData;
		}
		
		if (mode != 'upload') {
			req_data = {
				...data
			};
		}

		axios({
			method: type,
			url: base_url ? base_url : url,
			headers,
			data: req_data,
		}).then(res => {
			if(is_oss){
				return resolve(res)
			}
			res = res.data
			if (res.code == 1) {
				resolve(res)
			} else if (res.code == 0) {
				error_handle(res.info)
				reject(res)
			}
			else if (res.code == -2) {
				let url = window.location.href;
				window.location.href =config.hostUrl+ '/seller/login/auth?url=' + url
			}
			else if (res.code == -3) {
				router.replace('/')
			}
            else if (res.code == -4) {
            	Dialog.alert({
            	  title: '',
                  messageAlign:'left',
            	  message: res.info,
            	}).then(() => {
            	  Dialog.close()
            	});
            }
		}).catch(e => {
			error_handle(res.info)
			Toast(`请求失败,url = ${url}`, data, e);
			reject(e)
		})
	})
}

export default req;
