<template>
	<div class="sign_canvas">
		<div class="wrap_vertical" v-show="!showFull">
			<div class="canvas_main_vertical">
				<canvas ref="canvasVertical" id="canvas_vertical" ></canvas>
				<div class="handle_clear_vertical" @click="handleClear">重写</div>
				<div class="handle_full_vertical" @click="handleFull"><img src="./enlarge.png" ></div>
			</div>
			<div class="footer" @click="finish" v-show="fixed">
				{{sub_text}}
			</div>
		</div>
		<div class="wrap_transverse" v-show="showFull">
			<div class="canvas_main_transverse">
				<canvas ref="canvasTransverse" id="canvas_transverse" ></canvas>
				<div class="handle_clear_transverse" @click="handleFull">
                    <!-- 取消 -->
                    <van-icon name="arrow-left" />
                </div>
                <div class="handle_full_transverse" @click="handleClear">重写</div>
				<div class="finish_transverse" @click="finish">{{sub_text}}</div>
			</div>
		</div>
  	</div>
</template>

<script>
import SmoothSignature from "smooth-signature";
// import {rotateImage,upload} from '../../api/common/public.js'
import {upload_oss,rotateImage} from '../../assets/js/api'
import {Toast} from 'vant';
import req from '../../assets/js/req';

export default {
	emits: ["ok"],
	components: {
		[Toast.name]: Toast,
	},
	data() {
		return {
			showFull: false,
			is_loading:false,
			canvas_vertical:{
				width:0,
				height:0
			}
		};
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		fixed: {
			type: Boolean,
			default: true,
		},
        sing_img:{
            type: String,
            default: ''
        },
		sub_text: {
			type: String,
			default: '确定'
		}
	},
	watch: {
		show(newValue, oldValue) {
			
		},
	},
	mounted() {
		this.initSignatureVertical();
		this.initSigntureTransverse();
	},
	methods: {
		initSignatureVertical() {
			const canvas = document.getElementById("canvas_vertical");
			let canvasVerticalWidth = window.innerWidth - 30
			let canvasVerticalheight = 238
			this.canvas_vertical.width = canvasVerticalWidth
			this.canvas_vertical.height = canvasVerticalheight
			const options = {
				width: canvasVerticalWidth,
				height: canvasVerticalheight,
				minWidth: 2,
				maxWidth: 8,
				bgColor: '#FFFFFF',
			};
			this.signatureVertical = new SmoothSignature(canvas, options);
		},
		initSigntureTransverse() {
			const canvas = document.getElementById("canvas_transverse");
			const options = {
				width: window.innerWidth - 30,
				height: window.innerHeight - 30,
				minWidth: 4,
				maxWidth: 10,
				bgColor: '#FFFFFF',
			};
			this.signatureTransverse = new SmoothSignature(canvas, options);
		},
		handleClear() {
			if(this.showFull){
				this.signatureTransverse.clear();
			}else{
				this.signatureVertical.clear();
			}
		},
		handleFull() {
			this.handleClear()
			this.showFull = !this.showFull;
		},
		finish(){
			if(this.showFull){
				const isEmpty = this.signatureTransverse.isEmpty();
				if (isEmpty) {
					Toast.fail('请签名');
				}else{
					this.handlePreviewTransverse();
				}
			}else{
				const isEmpty = this.signatureVertical.isEmpty();
				if (isEmpty) {
					Toast.fail('请签名');
				}else{
					this.handlePreviewVertical();
				}
			}
		},
		handlePreviewVertical() {
			let imgData = this.$refs.canvasVertical.toDataURL('image/jpeg');
			let file = this.base64ToBlob(imgData, new Date().getTime() + '.jpeg');
			upload_oss({
				data: {file}
			}).then(res => {
				this.$emit('ok', res.upload_data.url);
			})
		},
		handlePreviewTransverse() {
			let that = this
			let imgData = this.$refs.canvasTransverse.toDataURL('image/jpeg');
			let file = this.base64ToBlob(imgData, new Date().getTime() + '.jpeg');
			upload_oss({
				data: {file}
			}).then(res => {
				let data = res.upload_data;
				rotateImage({
					route: data.url,
					angle: 90
				}).then(res => {
					that.$emit('ok', res.data.url);
					this.showFull = false
				})
			})
		},
        set_img_temp(imgdata){
			let that = this
			let canvas = document.getElementById('canvas_vertical');
			var ctx = canvas.getContext('2d')
			var img = new Image();
			img.src = imgdata;
			img.onload = function(){
				ctx.drawImage(img, 0, 0,that.canvas_vertical.width, that.canvas_vertical.height)
			}
        },
		handlePreviewTransverse_all() {
			let that = this
			if (this.is_loading == false) {
				this.is_loading = true;
				return new Promise((resolve, reject) => {
					let imgData = that.$refs.canvasTransverse.toDataURL('image/jpeg');
					let file = that.base64ToBlob(imgData, new Date().getTime() + '.jpeg');
					upload_oss({
						data: {file}
					}).then(res => {
						let data = res.upload_data;
						rotateImage({
							data: {
								route: data.url,
								angle: 90
							}
						}).then(res => {
							let img = new Image();
							img.setAttribute("crossOrigin", 'Anonymous')
							img.src = res.url;
							img.onload = () => {
								setTimeout(() => {
									//that.$emit('ok', res.url);
									that.signatureTransverse.clear()
									that.showFull = !that.showFull;
									setTimeout(() => {
										let canvas = document.getElementById('canvas_vertical');
										that.ctx = canvas.getContext('2d');
										that.stageInfo = canvas.getBoundingClientRect()
										that.ctx.drawImage(img, 0, 0,that.canvas_vertical.width, that.canvas_vertical.height)
										resolve();
									}, 1)
								}, 1)
							}
						})
					})
					that.is_loading = false;
				})
			}
		},
		base64ToBlob(dataurl, filename) {
			// 获取到base64编码
			const arr = dataurl.split(',')
			// 将base64编码转为字符串
			const bstr = window.atob(arr[1])
			let n = bstr.length
			const u8arr = new Uint8Array(n) // 创建初始化为0的，包含length个元素的无符号整型数组
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n)
			}
			return new File([u8arr], filename, {
				type: 'image/jpeg',
			})
		},

	},
};
</script>

<style lang="less" scoped>

.sign_canvas{
	background:#ffffff;
	font-size:16px;

	.wrap_vertical{
		width:auto;
		padding:14px 14px 10px 13px;

		.canvas_main_vertical{
			width:100%;
			position: relative;

			.handle_clear_vertical{
				position: absolute;
				right:10px;
				bottom:15px;
                color: #397dee;
			}

			.handle_full_vertical{
				position: absolute;
				right:10px;
				top:8px;
               img{
                   width: 18px;
                   height: 18px;
                   display: inline-block;
               }
			}
		}

		.footer {
			width: 100%;
			height: 44px;
			line-height: 44px;
			text-align: center;
			font-size: 18px;
			color: #fff;
			border-radius: 4px;
			background-color: #397DEE;
			margin-top: 18px;
		}
	}

	.wrap_transverse {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		padding:13px 14px 15px 14px;
		display: flex;
		justify-content: center;
		background: #ffffff;
		z-index: 10;

		.canvas_main_transverse{
			width:100%;
			position: relative;

			.handle_clear_transverse{
				transform: rotate(90deg);
				position: absolute;
				right:8px;
				top:4px;
                font-size: 16px;
                color: #397DEE;
			}

			.handle_full_transverse{
				transform: rotate(90deg);
				position: absolute;
				left:18px;
				bottom:140px;
                color: #397DEE;
			}

			.finish_transverse{
				width: 90px;
				height: 44px;
				line-height: 44px;
				border:0;
				border-radius: 4px;
				text-align: center;
				color: #fff;
				font-size: 14px;
				background: #397DEE;
				position: absolute;
				bottom: 35px;
				left:-10px;
				transform: rotate(90deg);
			}
		}
  	}

	canvas {
		flex: 1;
		border-radius: 5px;
		border: 1px dashed #ccc;
	}
}
</style>