<template>
	<div class="dealer">
		<div class='search_box'>
			<span class="icon iconfont icon-sousuo"></span>
			<input type="text" value="" class="seaech_input" placeholder="请输入出库单号" />
			<span class='serach_btn'>筛选</span>
		</div>
		<div class='list'>
			<div class="material">
				<div class="material_title">
					<span>润田公司销售出库单</span><span>经销商未读</span>
				</div>
				<div class='good_info'>
					<div class="info_item">
						<span>发送时间：</span><span>eee</span>
					</div>
					<div class="info_item">
						<span>客户名称：</span><span>eee</span>
					</div>
					<div class="info_item">
						<span>出库单号：</span><span>eee</span>
					</div>
					<div class="info_item">
						<span>产品明细：</span>
						<span>润田翠天然含硒矿泉水(蓝瓶)500ml*24瓶_130件润田翠天然含硒0件润田翠天然含硒0件润田翠天然含硒矿泉水(蓝瓶)500ml*24瓶_130件</span>
					</div>
					<div class="info_item">
						<span>发货金额：</span><span>eee</span>
					</div>
				</div>
				<div class='show_info'>
					<p><span>查看详情</span><span>></span></p>
				</div>
			</div>

			<div class="material" @click="go_details">
				<div class="material_title">
					<span>润田公司销售出库单</span><span>经销商未读</span>
				</div>
				<div class='good_info'>
					<div class="info_item">
						<span>发送时间：</span><span>eee</span>
					</div>
					<div class="info_item">
						<span>客户名称：</span><span>eee</span>
					</div>
					<div class="info_item">
						<span>出库单号：</span><span>eee</span>
					</div>
					<div class="info_item">
						<span>产品明细：</span>
						<span>润田翠天然含硒矿泉水(蓝瓶)500ml*24瓶_130件润田翠天然含硒0件润田翠天然含硒0件润田翠天然含硒矿泉水(蓝瓶)500ml*24瓶_130件</span>
					</div>
					<div class="info_item">
						<span>发货金额：</span><span>eee</span>
					</div>
				</div>
				<div class='show_info'>
					<p><span>查看详情</span><span>></span></p>
				</div>
			</div>
		</div>
		<div class='lefd_box' v-show="visiit">
			<div class='lefd_draw'>
				<div class='mar_top'>
					<p class="lable_p">时间范围</p>
					<p class='date_box'>
					<!-- 	<datetime class="data_itme" type="datetime" v-model="date"></datetime>-<datetime
							class="data_itme" v-model="date2"></datetime> -->
					</p>

				</div>
				<div class='mar_top'>
					<p class="lable_p">出库单类型</p>
					<p class='date_box'>
						<select v-model="selectItem" class='select_box' placeholder="请选择">
							<option class='option_item' value="1">桶装</option>
							<option class='option_item' value="2">瓶装</option>
						</select>
					</p>

				</div>
				<div class='botton_btn'>
					<p>重置</p><p>搜索</p>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	// import {
	// 	approvalInfo,
	// 	addInfo
	// } from '@/api/index.js'
	// import { Datetime } from 'vue-datetime'
	// import Datetime from '../components/datePickerDemo/datePickerDemo.vue'
	// import 'vue-datetime/dist/vue-datetime.css'
	export default {
		components: {
			// Datetime
		},
		data() {
			return {
				visiit:false,
				info: {
					goods_data: []
				},
				id: '',
				date: [],
				date2: [],
				query: {},
				selectItem:'',
			}
		},
		onLoad(e) {
			this.query = e;
			console.log('this.query', this.query);
			// this.get_info()
		},
		methods: {
			go_details(){
				console.log(1);
				this.$router.push({
					path: '/details',
				})
			}
			
		}
	}
</script>

<style lang="less" scoped>
	@import '../assets/css/base.less';

	page {
		background: #F7F7F7;
	}

	.material {
		margin-top: 10px;
		background: #fff;

		.material_title {
			height: 47px;
			line-height: 47px;
			opacity: 1;
			font-size: 30rpx;
			border-bottom: 1px solid #E4E3E5;
			padding-left: 16px;
			padding-right: 16px;
			display: flex;

			span:nth-child(1) {
				flex: 2;
				font-size: 16px;
			}

			span:nth-child(2) {
				flex: 1;
				text-align: right;
				color: #FF3B30;
			}
		}

		.material_box {
			padding-left: 16px;
			padding-right: 16px;
			padding-bottom: 8px;

			.material_item {
				display: flex;
				font-size: 15px;
				color: #333333;
				margin-bottom: 5px;

				span:nth-child(1) {
					flex: 3;
					span-align: left;
					color: #888888;
				}

				span:nth-child(2) {
					flex: 1;
					color: #333333;
					span-align: center;
				}

				span:nth-child(3) {
					flex: 1;
					span-align: center;
				}
			}

			.material_item:nth-child(1) {
				height: 34px;
				line-height: 34px;
				margin-bottom: 0rpx;
			}
		}
	}

	.list {
		padding: 0px 15px;
	}


	.header_top {
		display: flex;
		height: 38px;
		background: #fff;
		border-bottom: 1px solid #e6e6e6;
		padding: 11px 16px;

		.user_info {
			.timer {
				height: 14px;
				opacity: 1;
				font-size: 13px;
				font-weight: 400;
				text-align: left;
				color: #aaacb2;
				line-height: 16px;
			}
		}
	}

	.good_info {
		padding: 0px 16px;
		font-size: 14px;
		color: #666666;

		// height: ;
		p {
			line-height: 30px;
		}

		.info_item {
			// height: 28px;
			font-size: 14px;
			display: flex;
			margin: 7px 0px;

			span:nth-child(1) {
				flex: 1.1;
				color: #888888;
			}

			span:nth-child(2) {
				color: #333333;
				flex: 3.5;
				text-align: left;
			}

			div:nth-child(2) {
				display: 3.5;
				color: #333333;
			}


		}
	}

	.other_info {
		padding: 4px 16px;

		p {
			height: 42px;
			line-height: 42px;
			display: flex;
			font-size: 16px;

			span:nth-child(1) {
				flex: 1;
				color: #666666;
			}

			span:nth-child(2) {
				flex: 1;
				text-align: right;
				color: #000000;
			}
		}

		p:nth-child(1) {
			border-bottom: 1px solid #e6e6e6;
		}
	}

	.h30 {
		height: 30px;
	}

	.material {
		margin-top: 12px;
		border-radius: 6px;

		.material_title {
			border: none;
		}

		.other_info {
			padding: 4px 16px;
			padding-top: 0px;

			p {
				height: 42px;
				line-height: 42px;
				display: flex;
				font-size: 16px;

				span:nth-child(1) {
					flex: 1;
					color: #666666;

				}

				span:nth-child(2) {
					flex: 1;
					text-align: right;
					color: #000000;
				}
			}

			p:nth-child(1) {
				border-bottom: 1px solid #e6e6e6;
			}
		}
	}

	.show_info {
		height: 44px;
		opacity: 1;
		line-height: 44px;
		padding: 0px 16px;
		border-top: 1px solid #E5E5E5;
		border-radius: 0px 0px 6px 6px;

		p {
			display: flex;

			span {
				flex: 1;
				font-size: 14px;
			}

			span:nth-child(2) {
				text-align: right;
				color: #888888;
			}
		}
	}

	.search_box {
		height: 39px;
		// line-height: 25px;
		background: #fff;
		padding: 8px 15px;
		position: relative;

		.icon-sousuo {
			position: absolute;
			top: 18px;
			left: 26px;
			font-size: 18px;
		}

		.seaech_input {
			height: 37px;
			width: 265px;
			opacity: 1;
			background: #f6f7f8;
			border: 1px solid #ecedef;
			border-radius: 3px;
			padding-left: 30px;
		}

		.serach_btn {
			font-size: 15px;
			margin-left: 10px;
			color: #666666;
		}
	}

	.lefd_box {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, .4);
		padding-top: 10px;

		.lefd_draw {
			position: fixed;
			padding-left: 10px;
			right: 0;
			top: 0;
			width: 310px;
			height: 100%;
			background: #fff;
			
		}

	}

	.date_box {
		display: flex;
		align-items: center;
		color: #999999;

		.data_itme {
			flex: 1;
			color: #999999;
		}
	}

	.lable_p {
		font-size: 15px;
		padding-left: 15px;
		margin-bottom: 5px;
	}

	.mar_top {
		margin-top: 16px;
	}
	.select_box{
		width: 280px;
		height: 35px;
		opacity: 1;
		background: #ffffff;
		border: 1px solid #e2e2e2;
		border-radius: 3px;
		margin-left: 15px;
		margin-top: 5px;
		padding-left: 3px;
		padding-right: 5px;
		color: #999999;
		.option_item {
			height: 30px;
			line-height: 30px;
			color: #999999;
			
		}
	}
	.botton_btn{
		display: flex;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		
		p {
			flex: 1;
			text-align: center;
			background: #feffff;
			height: 50px;
			line-height: 50px;
			color: #000000;
			font-size: 16px;
			border-top: 1px solid #ECEDEE;
		}
		p:nth-child(2){
			color: #fff;
			border: none;
			background: #007BFF;
		}
	}
</style>
