import Vue from 'vue'
import Router from 'vue-router'
// import Relevance from '../views/Relevance' // 登录
import List from '../views/List.vue' // 登录
import Details from '../views/Details' // 登录

Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
export default new Router({
	mode: 'history',
	routes: [
		{
		    path: '/',
		    name: 'Relevance',
		    meta: {
		        title: '登录'
		    },
		    component: () => import('../views/Relevance.vue'),
		},
		{
		    path: '/details',
		    name: 'Details',
		    meta: {
		        title: '推送列表'
		    },
		    component: () => import('../views/Details.vue'),
		},
{
		    path: '/list',
		    name: 'List',
		    meta: {
		        title: '推送列表'
		    },
		    component: () => import('../views/List.vue'),
		},

	]
})
