<template>
    <div class="dealer">
        <div class="list_url" @click="go_url">
            <img src="../assets/img/notice.png" ><p> 消息列表</p>
        </div>
        <div class="header_top">
            <image :src="info.headimg" mode="" class="user_ing"></image>
            <div class="user_info">
                <div class="user_name">
                润田动销销售出库单
                <!-- -{{info.type==1?'桶装':'瓶装'}} -->
                </div>
            </div>
        </div>
        <div class="info">
        <!--  <div class="info_item" v-for="(item,index) in info.data1" :key='index'>
            <span>{{item.name}}:</span><span>{{item.value}}</span>
        </div> -->
            <div class="info_item" v-if="isshow.outcomeId">
                <span>出库单号:</span><span>{{info.outcomeId}}</span>
            </div>
            
            <div class="info_item"  v-if="isshow.create_time">
                <span>出库时间:</span><span>{{info.create_time}}</span>
            </div>
            
            <div class="info_item"  v-if="isshow.customer">
                <span>客户名称:</span><span>{{info.customer}}</span>
            </div>
            <div class="info_item"  v-if="isshow.consignee">
                <span>收&nbsp;货 &nbsp;人:</span><span>{{info.consignee}}</span>
            </div>
            <div class="info_item" v-if="isshow.sellerPhone">
                <span>电 &nbsp; &nbsp; &nbsp;话:</span><span>{{info.sellerPhone}}</span>
            </div>
            <div class="info_item" v-if="isshow.receiveAddress">
                <span>地 &nbsp; &nbsp; &nbsp;址:</span><span>{{info.receiveAddress}}</span>
            </div>       
        </div>
        <div class="material">
            <div class="material_title">
                产品明细
            </div>
            <div class='good_info table_box'>
                <table class="section">
                    <tr class="head">
                        <th v-for="(item,index) in goods_data.title" :key="index" class="item">{{item}}</th>
                    </tr>
                    <tr v-for="(item,index) in goods_data.data" :key="index" class="row">
                        <td v-for="(it,key) in item" :key="key" class="item">{{it}}</td>
                    </tr>
                </table>       
            </div>

        </div>
        <div class="material" v-if="info.type==1">
            <div class="material_title">
                空桶数量
            </div>
            <div class='good_info'>
            <!--  <div class="info_item" v-for="(item,index) in info.data2" :key='index'>
                <span>{{item.name}}:</span><span>{{item.value}}</span>
                </div> -->
                <div class="info_item" v-if="isshow.emptyBarrelInfo.stockCount">
                    <span>空&nbsp; 桶&nbsp; 库&nbsp; 存:</span><span>{{info.emptyBarrelInfo.stockCount}}</span>
                </div>
                <div class="info_item" v-if="isshow.emptyBarrelInfo.creditCount">
                    <span>空桶信用额度:</span><span>{{info.emptyBarrelInfo.creditCount}}</span>
                </div>  
                <div class="info_item" v-if="isshow.emptyBarrelInfo.creditCount">
                    <span>可用空桶额度:</span><span>{{info.emptyBarrelInfo.creditCount}}</span>
                </div> 
                <div class="info_item" v-if="isshow.emptyBarrelInfo.borrowCount">
                    <span>拖欠空桶数量:</span><span>{{info.emptyBarrelInfo.borrowCount}}</span>
                </div>  <div class="info_item" v-if="isshow.emptyBarrelInfo.availableCount">
                    <span>可用空桶数量:</span><span>{{info.emptyBarrelInfo.availableCount}}</span>
                </div>
                
            </div>

        </div>
        <div class="material other_info" v-if="isshow.totalOutcomeMoney || isshow.sellerAvailableMoney">
                <!-- <p class='' v-for="(item,index) in info.data3" :key='index'><span>{{item.name}}</span><span>{{item.value.toLocaleString()}}元</span></p> -->
                <p class='' v-if="isshow.totalOutcomeMoney">
                    <span>发货金额:</span>
                    <span>{{info.totalOutcomeMoney.toLocaleString()}}元</span>
                </p>
                <p class='' v-if="isshow.sellerAvailableMoney">
                    <span>可用金额:</span>
                    <span>{{info.sellerAvailableMoney.toLocaleString()}}元</span>
                </p>
        </div>
        <div class="material" v-if="info.show_sign == 1">
            <div class="material_title" v-if="info.sign_url != '' || (info.need_sign == 1 && info.is_sign == 1)">
                签名
            </div>
            <div v-if="info.need_sign == 1 && info.is_sign == 1">
                <Autograph @ok="saveSign"></Autograph>
            </div>
            <div style="width:100%" v-else-if="info.sign_url">
                <img :src="info.sign_url" style="width:100%;height:auto">
            </div>
        </div>
        <div class="material" v-if="info.show_sign == 0 && info.need_sign == 0 && info.is_sign == 1">
            <div class="footer" @click="saveConfirm">确定</div>
        </div>
    </div>
</template>

<script>
 import {
  auth,get_bill_info,seller_sign
 } from '@/assets/js/api.js';
 import Autograph from '../components/signature/Autograph.vue'
 import { Toast } from 'vant'
 export default {
    components:{
        Autograph
    },
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    data() {
    return {
            isshow:{
                emptyBarrelInfo:{}
            },
        info: {
    
        outcomeGoodsInfoList: [],
        data1:[],
        data2:[],
        data3:[],
        },
        goods_data:[],
        query: {},
        back_status:0
    }
    },
    created(e) {
        if(this.$route.query.token){
            localStorage.setItem('token', this.$route.query.token);
        }
        this.get_info()
    },
    watch: {
        id: {
				handler(data) {
                    console.log(1111)
                    if (!data) {
                        return false
                    }
					this.id = data
                    this.get_info()
				}
			}
    },
  
    methods: {
        go_url(){
            // this.$router.push({
            //     path: '/list',
            // })
            // this.$router.back()
            this.$emit('detail_return',this.back_status);
        },
        get_info() {
            var id_str = this.id
            if (id_str == '' || id_str == undefined || id_str == null) {
                // alert(this.$router.query)
                id_str = this.$route.query.id
            }
            get_bill_info({
                id: id_str
            }).then(res => {
                this.info = res.data.data;
                this.goods_data = res.data.goods_data;
                this.isshow = res.data.data1
            })
        },
        saveConfirm() {
            seller_sign({
                id:id
            }).then(res=> {
                this.back_status = 1;
                Toast.success('确认成功')
                // this.get_info()
                this.$emit('detail_return',this.back_status);
            })
        },
        saveSign(e) {
            seller_sign({
                id:this.id,
                sign_url:e
            }).then(res=> {
                this.back_status = 1;
                Toast.success('确认成功')
                this.get_info()
            })
        }
    
    }
 }
</script>

<style lang="less" scoped>
 @import '../assets/css/base.less';

 page {
  background: #F7F7F7;
 }

 .dealer {
  background: #F7F7F7;
 }

 .info {
  background: #fff;
        
  .info_item {
   display: flex;
   font-size: 15px;
   // line-height: 32px;

   span:nth-child(1) {
    // width: px;
                flex: 1;
                margin-right: 20px;
                text-align: justify;
    color: #999999;
   }

   span:nth-child(2) {
    flex: 4;
    color: #333;
   }
  }

  padding: 16px;
        // padding-left: 12px;
 }

 .info_item {
        margin-top: 5px;
  display: flex;
  font-size: 15px;
  // line-height: 32px;

  span:nth-child(1) {
   flex: 1;
   color: #999999;
            text-align: justify;
  }

  span:nth-child(2) {
   flex: 3.5;
   color: #333;
  }
 }

 .material {
  margin-top: 10px;
  background: #fff;

  .material_title {
   height: 47px;
   line-height: 47px;
   opacity: 1;
   font-size: 30rpx;
   border-bottom: 1px solid #E4E3E5;
   padding-left: 16px;
   padding-right: 16px;
  }

  .material_box {
   padding-left: 16px;
   padding-right: 16px;
   padding-bottom: 8px;

   .material_item {
    display: flex;
    font-size: 15px;
    color: #333333;
    margin-bottom: 5px;

    span:nth-child(1) {
     flex: 3;
     // span-align: left;
    }

    span:nth-child(2) {
     flex: 1;
     // span-align: center;
    }

    span:nth-child(3) {
     flex: 1;
     // span-align: center;
    }
   }

   .material_item:nth-child(1) {
    height: 34px;
    line-height: 34px;
    margin-bottom: 0rpx;
   }
  }
 }



 .header_top {
  display: flex;
  height: 26px;
  background: #fff;
  border-bottom: 1px solid #e6e6e6;
  padding: 11px 16px;
        
  .user_info {
   .timer {
    height: 14px;
    opacity: 1;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    color: #aaacb2;
    line-height: 16px;
   }
  }
 }

 .good_info {
  padding: 16px;
  font-size: 14px;
  color: #666666;
        // padding-left: 12px;

  p {
   line-height: 25px;
  }

  .info_item {

   span:nth-child(1) {
    flex: 1.6;
                margin-right: 0px;
                text-align: justify;
   }


  }
 }
 .table_box {
        padding: 15px 0 15px 16px;
        font-size: 15px;
		color: #333333;
		width: 92%;
		overflow-x: scroll;
        .section{
			position: relative;
			left: 1px;
			top: 1px;
		}
		.item {
			min-width: 85px;
			min-height: 45px;
			max-width: 85px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #FAFAFA;
			box-sizing: border-box;
			border: 1px solid #E8E8E8;
			margin-left: -1px;
			margin-top: -1px;
            padding: 0 5px 0 5px;
			text-align: center;
		}
		.head {
			display: flex;
		}
		.row{
			display: flex;
			.item{
				font-size: 15px;
				background: #fff;
			}
		}
    }
 .other_info {
  
  padding: 4px 16px;
  p {
  height: 42px;
  line-height: 42px; 
  display: flex;
  font-size: 16px;
  span:nth-child(1){
   flex: 1;
   color: #666666;
  }
  span:nth-child(2){
   flex: 1;
   text-align: right;
   color: #000000;
  }
  }
  p:nth-child(1) {
   border-bottom: 1px solid #e6e6e6;
  }
 }
 .h30 {
  height: 30px;
 }
    .list_url {
        height: 52.5px;
        width: 94%;
        background: #fff;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        border-top: 1px solid #e6e6e6;
        img {
            width: 20px;
            height: 20px;
            margin-right: 16px;
        }
    }

    .footer {
			width: 100%;
			height: 44px;
			line-height: 44px;
			text-align: center;
			font-size: 18px;
			color: #fff;
			border-radius: 4px;
			background-color: #397DEE;
			margin-top: 18px;
		}
</style>