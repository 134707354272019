<template>
  <div id="app">
    <!-- <HelloWorld msg="差旅集约化管理系统"/> -->
	 <!-- <router-view /> -->
    <router-view></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style lang="less">

</style>
